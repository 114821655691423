import { BaseModule } from '../../types';
import React, { MouseEventHandler } from 'react';
import { useSiteData } from '../../providers/SiteDataProvider';
import ImageUtil from '../../util/ImageUtil';
import { useRouter } from 'next/router';

const Teaser: BaseModule = ({ pageContent, moduleHead }) => {
    const { textUtil, getRelativeOrAbsoluteUrl } = useSiteData();
    const router = useRouter();

    const textContent = textUtil.formatText(pageContent.text);

    const image = ImageUtil.getImage(ImageUtil.getImageCompat(pageContent));

    const { imageUrl, imageSet } = image;

    const handleClick = (ev: React.MouseEvent) => {
        const link = pageContent.link;
        if (link) {
            const l = getRelativeOrAbsoluteUrl(link);
            if (l.isExternal) {
                window.open(l.href);
            } else {
                router.push(l.href).then();
            }
        }
    };

    return (
        <div className="teaserFullWidth abstractTeaser abstractComponent">
            <div
                className="m-teaser m-teaser--single h-background h-background--fullwidth"
                data-t-name="Teaser"
                data-t-id="15"
            >
                <div className="m-teaser__inner">
                    <div className="a-image" data-t-name="Image" data-t-id="16">
                        {image && (
                            <figure>
                                <img
                                    className="img-responsive"
                                    src={imageUrl}
                                    srcSet={imageSet}
                                    alt=""
                                />
                            </figure>
                        )}
                    </div>

                    <div className="m-teaser__content">
                        {moduleHead}
                        <div
                            dangerouslySetInnerHTML={{ __html: textContent }}
                        />

                        <div className="btn btn-default" onClick={handleClick}>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: pageContent.button || 'Edit',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Teaser;
